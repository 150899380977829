import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import AuthReducer from "./features/auth";
import AuthEventReducer from "./features/authEvent";

const persistConfig = {
    key: 'root',
    storage,
}

const reducers = combineReducers({ auth: AuthReducer, authEvent: AuthEventReducer });

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})

export {store}
