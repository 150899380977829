import {lazy} from 'react'

import {createBrowserRouter} from "react-router-dom";
const Media = lazy(() => import("../views/page/Admin/Media"));
const ScanMe = lazy(() => import("../views/page/ScanMe"));
const LoginBO = lazy(() => import("../views/page/LoginBO"));
const Admin = lazy(() => import("../views/page/Admin"));
const LaunchEvent = lazy(() => import("../views/page/Event/LaunchEvent"));
const EventLog = lazy(() => import("../views/page/Admin/Event/EventLog"));
const Dashboard = lazy(() => import("../views/page/Admin/Dashboard/Dashboard"));
const EventList = lazy(() => import("../views/page/Admin/Event/EventList"));
const Event = lazy(() => import("../views/page/Admin/Event/"));
const EventForm = lazy(() => import("../views/page/Admin/Event/EventForm"));
const UserForm = lazy(() => import("../views/page/Admin/User/UserForm"));
const UserList = lazy(() => import("../views/page/Admin/User/UserList"));
const PartnerList = lazy(() => import("../views/page/Admin/Partner/PartnerList"));
const NewPartner = lazy(() => import("../views/page/NewPartner"));
const PartnerForm = lazy(() => import("../views/page/Admin/Partner/PartnerForm"));
const Profile = lazy(() => import("../views/page/Admin/Partner/Profile"));
const CreateEventGame = lazy(() => import("../views/page/Admin/Event/CreateEventGame"));
const Home = lazy(() => import("../views/page/Home"));
const Demo = lazy(() => import("../views/page/Demo"));

const listRoute = [
    {
        path: "/admin/",
        Component: Admin,
        handle: {fullscreen: true},
        children: [
            {
                path: "",
                //loader: dashboardLoader,
                Component: Event,
                label: "Dashboard",
                icon: 'pi pi-prime'
            },
            {
                path: "event",
                Component: Event,
                label: "Quản lý Sự Kiện",
                icon: 'pi pi-calendar',
            },
            {
                path: "media",
                Component: Media,
                label: "Media",
                icon: 'pi pi-image',
            },
            /*{
                path: "newEventGame",
                Component: CreateEventGame,
                label: "Tạo Event",
                icon: 'pi pi-plus',
            },*/
            {
                path: "profile",
                Component: Profile,
                label: "Thương Hiệu",
                icon: 'pi pi-calendar',
            },
            /*{
                path: "event/:name",
                Component: EventForm,
                label: "Tạo Event",
                icon: 'pi pi-calendar-plus',
                isShowMenu: false
            },*/
            /*{
                path: "user",
                Component: UserList,
                label: "Quản lý User",
                icon: 'pi pi-users'
            },
            {
                path: "user/:name",
                Component: UserForm,
                label: "Tạo User",
                icon: 'pi pi-account',
                isShowMenu: false
            },*/
            /*{
                path: "event/log",
                Component: EventLog,
                label: "Quản lý Event Log",
                icon: 'pi pi-calendar',
                handle: {fullscreen: true}
            },
            {
                path: "user",
                Component: UserList,
                label: "Quản lý User",
                icon: 'pi pi-users'
            },
            {
                path: "user/:name",
                Component: UserForm,
                label: "Tạo User",
                icon: 'pi pi-account',
                isShowMenu: false
            },
            {
                path: "partner",
                Component: PartnerList,
                label: "Quản lý Partner",
                icon: 'pi pi-building'
            },*/
        ]
    },
    {
        path: "/",
        Component: Home,
    },
    {
        path: "/demo",
        Component: Demo,
    },
    {
        path: "/newPartner",
        Component: NewPartner,
    },
    {
        path: "/login",
        Component: LoginBO,
    },
    {
        path: "/event/:eventId/join",
        Component: ScanMe,
    },
    {
        path: "/event/:eventId/launch",
        Component: LaunchEvent,
    },
    {
        path: "/event/:eventId/adminLauncher",
        Component: LaunchEvent,
    }
]

const routers = createBrowserRouter(listRoute);

export {routers, listRoute}
